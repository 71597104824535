<template>
  <div class="card-base" @click="clickGame">
    <atomic-image class="card-base__img" :src="src" />

    <div v-if="gameBages?.length" class="bages">
      <atomic-bage v-for="(bage, bageIndex) in gameBages" :key="bageIndex" v-bind="bage" />
    </div>

    <div v-if="!isMobile" class="card-base__info" @click.stop="clickBtnPlay(true)">
      <div v-if="isLoggedIn" class="card-base__info-favourite">
        <client-only>
          <button-favorite :gameId="gameInfo?.id ?? ''" />
        </client-only>
      </div>

      <div class="card-base__info-actions">
        <button-play />
      </div>

      <div class="card-base__info-footer">
        <button-base v-if="gameInfo?.isDemoMode" class="btn-try" tag-name="span" @click.stop="clickBtnPlay(false)">
          Demo
        </button-base>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IGame } from '@skeleton/core/types';
  import type { IGameTag } from '~/types';
  import { ModalName } from '@skeleton/consts/modals';

  const props = defineProps<{
    gameInfo?: IGame;
  }>();

  const router = useRouter();
  const profileStore = useProfileStore();
  const { isLoggedIn, isTelegramUser } = storeToRefs(profileStore);

  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);

  const { openModalSync } = useModalStore();
  const { localizePath, getImageUrl, getContent } = useProjectMethods();
  const { taptic } = useHaptics();
  const emit = defineEmits(['close']);

  const gameTagsContent = computed(
    (): Maybe<IGameTag[]> =>
      getContent(globalComponentsContent.value, defaultLocaleGlobalComponentsContent.value, 'gameTags.gameTagsList')
  );

  const labelNames = props.gameInfo?.labels?.map((label: any) => label.name);
  const gameBages = gameTagsContent.value?.filter(bage => labelNames?.includes(bage.identity));

  const clickGame = (): void => {
    if (!props.gameInfo) return;

    taptic('soft');
    const { openGame } = useMobileGameLogic(props.gameInfo);
    openGame();
    emit('close');
  };

  const clickBtnPlay = (isReal: boolean): void => {
    const gamePath = localizePath(`/game/${props.gameInfo?.identity}`);

    if (!isReal) {
      router.push(gamePath);
      return;
    }

    if (!isLoggedIn.value) {
      if (!isTelegramUser.value) {
        openModalSync(ModalName.SIGN_UP);

        if (props.gameInfo?.isDemoMode) {
          router.push({ path: gamePath, query: { additionalParam: 'tryToPlayNotReg' } });
        }

        return;
      }
    }

    router.push(`${gamePath}?real=true`);
  };

  const src = computed(() => {
    if (props.gameInfo?.images?.hasOwnProperty('3x4')) {
      return getImageUrl(props.gameInfo.images, 'vertical');
    }
    return '';
  });
</script>

<style src="~/assets/styles/components/card/base.scss" lang="scss" />
